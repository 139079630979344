import { BehaviorSubject } from 'rxjs';
import { intitule } from '../constant/intitule';
import { toast } from 'react-toastify';
import axios from 'axios';

//const serverUrl = "http://13.48.177.86:8080/api/v1";
const serverUrl = "https://backend.tcf-canada-training.com/api/v1";
const tokenSubject = new BehaviorSubject(sessionStorage.getItem('token'));

const fetchData = async (endPoint, token) => {
  //toast.dismiss();
  
  
  try {
    const response = await axios.get(`${serverUrl}${endPoint}`, {
      headers: {
        'Authorization': token
      }
    });
    return response.data;
  } catch (error) {
    handleFetchError(error);
  } finally {
    //toast.dismiss();
  }
  
};

const handleFetchError = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
    
      setToken(null);
      sessionStorage.clear();
    } else {
     
    }
  } else if (error.request) {
    
  } else {
    
  }
};

function FindByIntitule (array, intitule) {
  const result= array.find(item=> item.intitule.toUpperCase()===intitule.toUpperCase())
  
 
 return result.id
};


export const setToken = async (newToken, userId) => {
  
  tokenSubject.next(newToken); 
  if (newToken && userId) {
    try {
      const user = await fetchData(`/accounts/get/${userId}`, newToken);
      sessionStorage.setItem('user', !!user.length ? JSON.stringify(user) : null);

      const trainingData = await fetchData('/training/', newToken);
      
      const id = FindByIntitule(trainingData, intitule.tcfCanada);
      sessionStorage.setItem('idTcf', id);
      

      const userTrainingData = await fetchData(`/training/get/${userId}/f/${id}`, newToken);
      const idComprehensionEcrite = FindByIntitule(userTrainingData.sections, intitule.comprehensionEcrite);
      const idComprehensionOrale = FindByIntitule(userTrainingData.sections, intitule.comprehensionOrale);
      

      sessionStorage.setItem('idComprehensionEcrite', idComprehensionEcrite);
      sessionStorage.setItem('idComprehensionOral', idComprehensionOrale);
    } catch (error) {
     // console.error('Error setting token:', error);
    }
  }
  sessionStorage.setItem('token', newToken);
  sessionStorage.setItem('userId', userId);
};

export const getToken = () => tokenSubject.asObservable();
