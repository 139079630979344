import React, { useState, useEffect } from 'react'
import './style.css'
import Image from "../../image/img2.jpg";
import ResultImage from '../../image/image.png';
import QuestionLevel from '../../components/QuestionLevel/QuestionLevel';
import Question from '../../components/Question/Question';
import Timer from '../../components/Timer/Timer';
import { useNavigate, useParams } from 'react-router-dom';
import useAxiosFetch from '../../Hooks/useAxiosFetch';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading/Loading';
import ErrorSection from '../../components/ErrorSection/ErrorSection';

const ComprehensionOraleTest = () => {

    const {id} = useParams()
    const userId = sessionStorage.getItem("userId")
    const {data, fetchError, isFetching, status} = useAxiosFetch(`training/get/${userId}/m/${id}`)
    
    
    const questions =  data.questions ? data.questions : [] ;
    const numQuestion = data.questions ? (data.questions).length : 39;
    const QuestionTime = 60


    const [timeLeft, setTimeLeft] = useState(QuestionTime*60);
    const [score, setScore] = useState({level: "A1", points: 0, Qcorrect: 0, Tpoints: 0})
    const [showResult, setShowResult] = useState(false)
    const [actualQuestion, setActualQuestion] = useState(1);
    const [reponse, setReponse] = useState(Array(numQuestion).fill(""));
    const [isComplete, setIsComplete] = useState(false);
    const [calculated, setCalculated] = useState(false);
    const navigate = useNavigate()
    const levels = ['A1','A2','B1','B3','C1','C2'];

    useEffect(() => {
        if(fetchError) toast.error(fetchError)
    },[fetchError])

    
    useEffect(() => {
       
        if (timeLeft <= 0 || isComplete){
            handleResult()
            return
        }

        const intervalId = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft, isComplete]);

    
    const handleShowResultat = () => {
        setShowResult(!showResult)
    }

    
    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

   const getPoint = (i) => {
        if(i >= 1 && i <= 4) return 3
        else if(i <= 10) return 9
        else if(i <= 19) return 15
        else if(i <= 29) return 21
        else if(i <= 35) return 26
        else if(i <= 39) return 33
        else return 0
    }
    
    const handleRestart = () => {
        setScore({level: "", points: 0, Qcorrect: 0, Tpoints: 0})
        setActualQuestion(1)
        setReponse(Array(numQuestion).fill(""))
        setTimeLeft(QuestionTime*60)
        setIsComplete(false)
        
    }

    
    const handleResult = () => {
        
        if(!calculated){
           var points = 0 
            questions.map((question, ind) => {
                let answer = question?.reponses
                
                
                
                if(answer.find(item => item?.id === reponse[ind]).veracite){
                    setScore((score) => {
                    return { 
                        ...score,
                        points: score.points + getPoint(ind + 1),
                        Qcorrect: score.Qcorrect + 1
                    }
                    })
                }
                setScore((score) => {
                    return { 
                    ...score,
                    Tpoints: score.Tpoints + getPoint(ind + 1)
                    }
                })
            })
            var level = ''
            if(points < 100) level = 'A1'
            else if(points < 331) level = 'A2'
            else if(points <= 398) level = 'B1'
            else if(points <= 458) level = 'B2'
            else if(points <= 549) level = 'C1'
            else level = 'C2'

            setScore((score) => {
                return {
                    ...score,
                    level
                }
            })
            setCalculated(true)
        }
    }

    
    const handleComplete = () => {
        
        let complete = true;
        let i = 0;
        while(i < numQuestion && complete ){
            complete = !!reponse[i];
            i++;
        }
        if(complete){
            handleResult()
        }
        setIsComplete(complete)
    }

    
    const handleQuestion = (num) => {
        handleComplete()
        setActualQuestion(num < numQuestion ? num : numQuestion)
    }

    
    const handleResponse = (val) => {
        let nResponse = reponse
        nResponse[actualQuestion-1] = val
        setReponse(nResponse)
        
    }

    
    const handleReviser = () => {
        
        if(reponse[actualQuestion - 1] === -1){
            handleResponse("")
        }
        else if(!(reponse[actualQuestion - 1])){
            handleResponse(-1)
        } 
    }
    
    if(isFetching) return <Loading />

    
   if(numQuestion <= 0){
        return (
            <div className='d-flex flex-column align-items-center'>
                <p className="text-danger fs-1">No Question Found</p>
                <ErrorSection errorCode={status} />
            </div>
        )
   } 

    return (
        <main>
            <section className="section w-100 position-relative mt-0" style={{height: '30rem'}}>
                <img src={Image} alt="background" className="position-absolute start-0 top-0 w-100 h-100" />
                <div className="position-absolute start-0 top-0 w-100 h-100 d-flex justify-content-center align-items-center gradient3"> 
                    <h2 className="text-center mb-2 text-white"><strong>{data.intitule} TEST {data.ordre}</strong></h2>
                </div>
            </section>
            {isComplete || timeLeft <= 0  ? (
                <section className="test-resultat m-0">
                    <div className='test-oral-container mx-auto'>
                        <h3 className='bold mb-4'>Resultat</h3>
                        <p className='fs-5 text-left'>{`${score.Qcorrect} of ${numQuestion}`} Question answered correctly</p>
                        <p className='fs-5 text-left'>Votre temps {formatTime(QuestionTime*60 - timeLeft)}</p>
                        <div className='resultat-center mx-auto'>
                            <div className='resultat p-3 mt-2 w-100  rounded d-flex align-items-center justify-content-center'>
                                <h4><strong>You have reached {`${score.points} of ${score.Tpoints}`} point(s), {}</strong></h4>
                            </div>
                            <div className='d-flex flex-column mt-5'>
                                <div className='d-flex align-items-center'>
                                    <p className='fs-5 text-width'>Note moyenne</p>
                                    <div className='flex-grow-1 d-flex align-items-center gap-2'>
                                        <div style={{width: '69.68%', background: 'rgba(215, 215, 215, 0.728)'}} className='py-3 rounded-2 d-md-flex d-none'></div>
                                        <span style={{color: 'rgba(215, 215, 215, 0.728)'}}>69.68%</span>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <p className='fs-5 text-width'><strong>Votre score</strong></p>
                                    <div className='flex-grow-1 d-flex align-items-center gap-2'>
                                        <div style={{width: `${score.points/score.Tpoints*100}%`}} className='py-3 rounded-2 bg-dark d-md-flex d-none'></div>
                                        <span>{(score.points/score.Tpoints*100).toFixed(2)}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <h2 className='text-center mt-3'>Niveau CECRL</h2>
                        <div className='d-flex level mx-auto resultat-center border-start border-dark'>
                            {levels.map((level) => (
                                <div className='flex-grow-1 py-4 d-flex flex-column level-box text-center gap-3 border-end border-dark'>
                                    <h4>{level}</h4>
                                    {score.level >= level && (
                                        <div className='bg-dark w-100 p-3'></div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <img src={ResultImage} alt="Result"  className='w-100' />
                        <div className='d-flex gap-3 justify-content-center mt-5'>
                            <button className="button rounded-pill" onClick={handleShowResultat}>Voir les reponses</button>
                            <button className="button rounded-pill" onClick={handleRestart}>Relancer le Quiz</button>
                        </div>
                        <div className='mt-5'>
                            {showResult && (
                                <>
                                    <QuestionLevel 
                                        handleQuestion={handleQuestion}   
                                        actualQuestion={actualQuestion}
                                        reponse={reponse}
                                        questions={questions}
                                        handleReviser={handleReviser}
                                        result={true}
                                    />
                                    <div className='point d-flex justify-content-end align-items-end mt-5 mb-2 p-1'>
                                        <span><strong>{/*questions[actualQuestion - 1]?.point*/getPoint(actualQuestion)} points</strong></span>
                                    </div>
                                    {questions.map((question, index) => (
                                        <Question 
                                            actualQuestion={index + 1}
                                            question={question}
                                            handleResponse={handleResponse} 
                                            handleQuestion={handleQuestion}
                                            reponse={reponse}
                                            result={true}
                                            oral={true}
                                        />
                                    ))}
                                    
                                </>
                            )}
                        </div>
                    </div>
                </section>
            ):(
                <>
                    <section className="test-section m-0">
                        <div className='test-container mx-auto'>
                            <QuestionLevel 
                                handleQuestion={handleQuestion}   
                                actualQuestion={actualQuestion}
                                reponse={reponse}
                                handleReviser={handleReviser}
                            />
                        
                            <div className='point d-flex justify-content-end align-items-end mt-5 mb-2 p-1'>
                                <span><strong>{/*questions[actualQuestion - 1]?.point*/getPoint(actualQuestion)} point</strong></span>
                            </div>
                            <Question 
                                actualQuestion={actualQuestion}
                                question={questions[actualQuestion - 1]}
                                handleResponse={handleResponse} 
                                handleQuestion={handleQuestion}
                                reponse={reponse}
                                oral={true}
                                numQuestion={numQuestion}
                            />
                            
                        </div>
                    </section>
                    <Timer 
                        timeLeft={timeLeft}
                        totalTime={QuestionTime*60}
                        formatTime={formatTime}
                    />
                </>
            )}
        </main>
    )
}

export default ComprehensionOraleTest